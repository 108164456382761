<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <van-form style="background-color: transparent">
        <div class="my-card">
          <van-field
            readonly
            v-model="viewData.CreateUserName"
            name="CreateUserName"
            label="加班申请人"
          />
          <van-field
            readonly
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="加班开始时间"
          />
          <van-field
            readonly
            v-model="viewData.EndDate"
            name="EndDate"
            label="加班结束时间"
          />
          <van-field
            readonly
            type="number"
            v-model="viewData.OverTimeDays"
            name="OverTimeDays"
            label="请假天数"
          />
          <van-field
            readonly
            v-model="viewData.OverTimeDesc"
            name="OverTimeDesc"
            rows="2"
            label="加班事由"
            type="textarea"
          />
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                readonly
                v-model="viewData.AccessoryPostList"
                :deletable="false"
                :preview-full-image="false"
                accept="*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <div class="my-card">
          <van-collapse v-model="activeNames">
            <van-collapse-item title="审核流程" name="1">
              <van-steps
                direction="vertical"
                :active="flowList.length > 0 ? flowList.length - 1 : 0"
                active-color="#47AFA7"
              >
                <van-step v-for="(item, i) in flowList" :key="i">
                  <div class="flow-item">
                    <div class="flow-top">
                      <van-tag
                        v-if="
                          item.AuditStateName === '未审核' ||
                            item.AuditStateName === '驳回'
                        "
                        color="#f5222d"
                        type="primary"
                        size="large"
                        plain
                      >
                        {{ item.AuditStateName }}
                      </van-tag>
                      <van-tag
                        v-if="item.AuditStateName === '通过'"
                        color="#1890ff"
                        type="primary"
                        size="large"
                        plain
                      >
                        {{ item.AuditStateName }}
                      </van-tag>
                      <span class="name">{{ item.AuditUserName }}</span>
                      <span class="time">{{ item.AuditDateStr }}</span>
                    </div>
                    <div class="flow-bottom">
                      审核意见：{{ item.AuditDesc }}
                    </div>
                  </div>
                </van-step>
              </van-steps>
            </van-collapse-item>
          </van-collapse>
        </div>

        <template>
          <div
            v-if="viewData.LeaveStateName === '未审核'"
            class="confirm-box"
          ></div>
          <div
            v-if="viewData.LeaveStateName === '通过'"
            class="confirm-box type1"
          ></div>
          <div
            v-if="viewData.LeaveStateName === '驳回'"
            class="confirm-box type2"
          ></div>
        </template>
      </van-form>
      <!-- 按钮组 -->
      <div v-if="viewData.LeaveStateName == '未审核'" class="btn-box">
        <van-button
          type="warning"
          style="width: 40%; float: left"
          @click="dialogVisible = true"
          >驳回</van-button
        >
        <van-button
          color="#47AFA7"
          type="info"
          style="width: 40%; float: right"
          @click="handlePass"
          >通过</van-button
        >
      </div>
      <!-- 驳回弹窗 -->
      <van-dialog
        v-model="dialogVisible"
        title="驳回"
        :beforeClose="beforeClose"
        showCancelButton
      >
        <van-field
          required
          v-model="AuditDesc"
          name="LeaveDesc"
          rows="2"
          label="驳回原因"
          type="textarea"
          placeholder="请输入驳回原因"
        />
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Dialog,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Toast
} from "vant";
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag
  },
  data() {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      viewData: {
        AccessoryPostList: [],
        flowList: []
      },
      leaveTypeList: [],
      showPicker: false,
      showTime: false,
      showTime2: false,
      loading: false,
      dialogVisible: false,
      AuditDesc: "",
      activeNames: ["1"],
      flowList: [],
      auditId:null
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
      this.getFlow();
    }

    if(this.$route.query.auditId){
        this.auditId = this.$route.query.auditId
    }
  },
  methods: {
    getFlow() {
      // 请求审核流程
      this.$axios
        .get("/api/Auidt/AuditInfo", {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.flowList = res.data;
          }
        });
    },
    getDetail() {
      this.$axios
        .get("/api/OverTime/Get", {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList
            };
          }
        });
    },
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then(res => {
        Toast.clear();
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl
          });
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    handlePass() {
      let that = this;
      this.$axios
        .post("/api/OverTime/Audit", {
          AuditDesc: "通过",
          AuditID: this.auditId,
          AuditState: 3
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace("AuditSchedule");
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        if (!this.AuditDesc) {
          this.$toast.fail("请填写驳回原因！");
          done(false);
        } else {
          this.$axios
            .post("/api/OverTime/Audit", {
              AuditDesc: this.AuditDesc,
              AuditID: this.auditId,
              AuditState: 4
            })
            .then(res => {
              if (res.code === 200) {
                this.$toast.success(res.msg || "操作成功");
                this.AuditDesc = "";
                done();
                setTimeout(() => {
                  that.$router.replace("AuditSchedule");
                }, 1000);
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        }
      } else {
        this.AuditDesc = "";
        done();
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
